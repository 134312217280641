<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card v-if="org">
      <v-card-title class="headline">Permissions for {{org.name}}</v-card-title>
      <v-card-text>      
        <p>
          By default only you have access to your organization. You can optionally grant access to others as well. To do this you need to get their profile ID. They can easily find that by going to the 'Edit Profile' page and then scroll to the bottom of the page to copy their profile ID. 
        </p>
        <p>
          Please note that the persons you provide access will have the exact same permissions as you have and can therefor also invite others.
        </p>

        <v-alert v-if="!orgUtil.isProPlan()" type="info" outlined>
          <p><router-link class="info--text" :to="{name: 'orgmanagerSubscription', params: {id:org.id}}">Upgrade to a PRO plan</router-link> to be able to set permissions.</p>
        </v-alert>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="permissions"
        sort-by="name"
        :items-per-page="25"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat color="white" class="mx-4">
            <v-toolbar-title class="ml-0 subtitle">Permissions</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="accent" class="mb-2" :disabled="!orgUtil.isProPlan()" @click="showAddUserDialog=true">Add</v-btn>
          </v-toolbar>
        </template>
          <template v-slot:item.roles="{ item }">
            {{ item.roles.map(x => $helpers.displayText(siteData.roles, x)).join(", ") }}
          </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-4" @click="editPermissions(item)">
            fa-pencil
          </v-icon>
          <v-icon small class="mr-4" @click="deletePermissions(item.name, item.id)">
            fa-trash
          </v-icon>
        </template>
        <template v-slot:no-data>
          <i>No extra permissions granted, only you will have access to this organization.</i>
        </template>
      </v-data-table>
    </v-card>


    <v-dialog v-model="showAddUserDialog">
      <v-card>
        <v-card-title>Add an admin</v-card-title>
        <v-card-text>
          Add an admin user to this organization. This user will have access to (certain parts of) the backend of this organizations and all events belonging to it. Only add users you trust.
        </v-card-text>
        <v-card-text>
          You need to have the Profile ID of the user you want to add. The user can find this (and send to you) from the Edit Profile screen in the app or website when they are logged in.
        </v-card-text>
        <v-card-text>
          <v-text-field
            v-model="profileId" 
            label="Profile ID"
            persistent-hint
            hint="The user can find this on their Edit Profile screen"
            required
            autofocus
            />
        </v-card-text>
        <v-card-text>
          Select one or more roles for the user:
          <v-checkbox 
            v-for="(item, index) in siteData.roles" 
            multiple 
            v-model="selectedRoles" 
            :key="index" 
            :value="item.type" 
            :label="item.text" 
            :disabled="!orgUtil.isEnterprisePlan()"
            :hint="item.description"
            persistent-hint
            />
          <v-alert v-if="!orgUtil.isEnterprisePlan()" type="info" outlined class="mt-4">Upgrade to an Enterprise Plan to be able to set more fine-grained permissions.</v-alert>
        </v-card-text>
        
        <v-card-text>
          <v-btn color="primary" :disabled="!profileId || !selectedRoles || !selectedRoles.length" @click="addPermissions">Save</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>    
  </div>      
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import orgManagerService from "@/services/orgManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import OrgUtil from "@/util/orgUtil";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Org",
  components: {
  },
  props: {
    org: Object,
  },
  data() {
    return {
      siteData: siteData,
      tenant: tenant,
      showAddUserDialog: false,
      permissions: [],
      profileId: null,
      selectedRoles: ["FULL_ACCESS"],
      headers: [
        { text: 'Name', align: 'start', sortable: true, filterable: true, value: 'name',},
        { text: 'Roles', align: 'start', sortable: true, filterable: true, value: 'roles',},
        { text: 'Actions', align: 'start', sortable: false, filterable: false, value: 'actions',},
      ],
    };
  },
  async mounted() {
    await this.getData();
    
    EventBus.$on('login-state-change', async user => {
      await this.getData();
    });
  },
  methods: {
    async getGroup(id) {
      this.permissions = (await orgManagerService.getPermissions(id)).data.data || [];
    },

    async addPermissions() {
      if (this.profileId && this.selectedRoles && this.selectedRoles.length) {
        const response = (await orgManagerService.addPermissions(this.org.id, this.profileId, this.selectedRoles)).data;
        await this.getData();
        this.showAddUserDialog = false;
        this.$helpers.toastResponse(this, response, 'Permission added successfully.');
      }
    },

    async editPermissions(permissions) {
      this.profileId = permissions.id;
      this.selectedRoles = permissions.roles;
      this.showAddUserDialog = true;
    },

    async deletePermissions(name, profileId) {
      if (confirm(`Are you sure you want to remove access by ${name}?`)) {
        const response = (await orgManagerService.deletePermissions(this.org.id, profileId)).data;
        await this.getData();
        this.$helpers.toastResponse(this, response, 'Permission revoked successfully.');
      }
    },

    async getData() {
      if (this.user) {
        await this.getGroup(this.$route.params.id);
      }
    },

  },
  computed: {
    
    breadcrumbItems() {
      return !this.org ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Organizations', exact: true, to: { name: 'orgmanager' } },
        { text: this.org.name, exact: true, to: { name: 'orgmanagerView', params: {id: this.org.id}} },
        { text: 'Permissions', disabled: true },
      ];
    },
    orgUtil() {
      return new OrgUtil(this, this.org);
    },
    ...mapGetters({
      user: "user"
    })
  },
  
};
</script>

